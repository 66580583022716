var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { StatusClassNames } from "../types/StatusClassNames";
import EventBus from "../events/EventBus";
import { MOSCOW_FIAS, ST_PETERSBURG_FIAS } from "./order/city_helpers";
var locationElements = document.querySelectorAll('.location');
var STORAGE_NAME = 'location_info';
function show(location) {
    var e_1, _a;
    try {
        for (var locationElements_1 = __values(locationElements), locationElements_1_1 = locationElements_1.next(); !locationElements_1_1.done; locationElements_1_1 = locationElements_1.next()) {
            var el = locationElements_1_1.value;
            el.querySelector('.btn__label').textContent = location.name;
            el.classList.add(StatusClassNames.ready);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (locationElements_1_1 && !locationElements_1_1.done && (_a = locationElements_1.return)) _a.call(locationElements_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
function readLocation() {
    var location = window.localStorage.getItem(STORAGE_NAME);
    if (location) {
        var data = JSON.parse(location);
        return __assign(__assign({}, data), { isFresh: new Date(data.updated).toLocaleDateString() === new Date().toLocaleDateString() });
    }
    return location ? JSON.parse(location) : undefined;
}
function storeLocation(location) {
    window.localStorage.setItem(STORAGE_NAME, JSON.stringify(location));
}
function requestLocation() {
    return fetch('/api/location').then(function (response) { return response.json(); });
}
export function getLocation() {
    return readLocation();
}
export function detectLocation() {
    var _this = this;
    return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
        var location;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    location = readLocation();
                    if (location && (location.manual || location.isFresh)) {
                        resolve(location);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, requestLocation()];
                case 1:
                    location = _a.sent();
                    resolve(location);
                    return [2 /*return*/];
            }
        });
    }); });
}
export function setLocation(location, manual) {
    if (manual === void 0) { manual = false; }
    location = __assign(__assign({}, location), { manual: manual, updated: new Date().toISOString(), isFresh: true });
    storeLocation(location);
    show(location);
    EventBus.emit('city:update', location);
}
export default function initLocation() {
    return __awaiter(this, void 0, void 0, function () {
        var location;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    location = readLocation();
                    if (!!location) return [3 /*break*/, 2];
                    return [4 /*yield*/, requestLocation()];
                case 1:
                    location = _a.sent();
                    setLocation(location);
                    return [3 /*break*/, 3];
                case 2:
                    show(location);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function isMoscow(location) {
    return (location).fias === MOSCOW_FIAS;
}
export function isStPetersburg(location) {
    return location.fias === ST_PETERSBURG_FIAS;
}
